import React from 'react'
import { getStorage } from '../modals/Storage';
import { AVATARS_ARR } from '../modals/Constants';

function TodayWinners(){

    return (
        <div className="col-view min-h w-100 pd-10 mg-b-10em">
            <div className="col-view br-right-t br-left-t pd-10-15 bg-blue-grad">
              <span className='cl-white ft-sz-18'>Top Winners</span>
              <span className='cl-l-white ft-sz-14'>Showwing Top 15 Winners.</span>
            </div>

            {/* <div className="col-view mg-t-15">
              {getStorage("winnersArr") && JSON.parse(getStorage("winnersArr")).map((data, index) => (
                <div key={index} className="row-view mg-t-15 sb-view pd-0-15">
                  <div className='row-view'>
                    <span className='w-20-p cl-primary-txt ft-sz-20 ft-wgt-b'>{index+1}</span>

                    <div className="h-w-45 ovf-hidden br-50 mg-l-40">
                      <img src={AVATARS_ARR[Number(data['avatar_id'])-1]['avatarURL']} className="h-w-45" alt="icon"/>
                    </div>
                    <span className='cl-l-white ft-sz-16 mg-l-10'>{data['mobile_num']}</span>
                  </div>
                  <span className="min-w-90 txt-a-center cl-white ft-sz-16 pd-5-15 br-10 bg-blue-grad">₹{data['winning_amount']}</span>
                </div>
              ))}
            </div> */}

        </div>
    );
}

export default TodayWinners;