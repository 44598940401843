import React, { useEffect,useState } from 'react';
import '../../MainStyle.css';
import { useNavigate } from 'react-router-dom';
import { API_ACCESS_URL,openNewPage } from '../modals/Constants';
import { deleteStorage,getStorage } from '../modals/Storage';
import LoadingDialog from '../dialogs/LoadingDialog';

function AccountProgress(){
    const navigate = useNavigate();
    const [pageConst, setConstants] = useState({
        pageTitle: "Account Progress",
        isLoadingShow: false,
        isAccountUnderVerification: false,
    });

    const updateLoadingDialog = (data) =>{
      setConstants(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
    }

    const rechargeNow = () =>{
        if(!pageConst.isAccountUnderVerification && getStorage("paymentURL")!="" && Number(getStorage("registrationFee")) > 0){
          openNewPage(getStorage("paymentURL")+"?am="+getStorage("registrationFee")+"&crudentials="+getStorage("uid"));
        }
    }

    const getAccountStatus = () => {
      updateLoadingDialog(true);

      async function requestAPI(url) {

          try {
            const res = await fetch(url, {
              method: "GET",
              headers: {
                'Content-Type': 'application/json',
                'Route': 'route-account-status',
                'AuthToken': getStorage("secret"),
              }
            });

            const data = await res.json();
            updateLoadingDialog(false);
            
            if(data.status_code=="success"){
              navigate('/home', { replace: true });
            }else if(data.status_code=="account_verification"){
              setConstants(previousState => {
                return { ...previousState, isAccountUnderVerification: true }
              });
            }else if(data.status_code=="authorization_error"){
              deleteStorage();
              navigate('/login', { replace: true });
            }
            
          } catch (error) {
            updateLoadingDialog(false);
          }
      };

      requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid"));
  }

    useEffect(() => {
      getAccountStatus();
    }, []);

    return (
      <div className="v-center bg-black">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-primary">
        <LoadingDialog intentData={pageConst}/>

          <div className="col-view br-right-t br-left-t mg-b-15">

            <div className="col-view pd-10-15 bg-blue-grad">
              <div className="col-view mg-l-10">
                <p className="ft-sz-25 cl-white">Account Verification</p>
                <p className="ft-sz-14 cl-white">Showing all processes to activate your account.</p>
              </div>
            </div>

            <div className='col-view pd-5-15'>
              <div className={`col-view`}>
                <div className="col-view mg-t-15 br-5 bg-white ovf-hidden">
                 <span className='cl-l-grey ft-sz-16 pd-10-15 mg-l-10'>1. Signup Process</span>
                 <span className='cl-l-grey ft-sz-16 pd-10-15 mg-l-10'>2. Kyc Process</span>

                 <div className={`w-100 row-view pd-5-10 mg-t-10 bg-green`}>
                   <p className='cl-white'>Completed&nbsp;</p>
                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{"fill": "rgba(255, 255, 255, 0.9)"}}><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>
                 </div>
                </div>

                <div className="col-view mg-t-15 br-5 bg-white ovf-hidden">
                 <div className={`w-100 row-view pd-5-10 bg-blue-grad`}>
                   <p className='cl-white pd-5-10 ft-sz-18'>3. Registration Fee</p>
                 </div>

                 <span className='cl-black pd-5-10 mg-t-15'>You need to pay Registration Fee to Activate your Account.</span>
                 <span className='cl-black pd-5-10'>After complete process your account will be activated with in 10-30 minutes.</span>

                 <div className={`w-100 v-center pd-10 ft-sz-18 mg-t-15 cl-white bg-blue-grad ${pageConst.isAccountUnderVerification ? 'hide-v' : ''}`} onClick={() => rechargeNow()}>
                  Continue to Pay
                  </div>

                  <div className={`w-100 row-view pd-5-10 mg-t-10 bg-blue-grad ${!pageConst.isAccountUnderVerification ? 'hide-v' : ''}`}>
                   <p className='cl-white'>Under Verification&nbsp;</p>
                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{"fill": "rgba(255, 255, 255, 0.9)"}}><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M11 11h2v6h-2zm0-4h2v2h-2z"></path></svg>
                 </div>

                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    );
}

export default AccountProgress;