import React, { useRef,useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import LoadingDialog from '../dialogs/LoadingDialog';
import '../../MainStyle.css';
import BottomNavbar from '../other-components/BottomNavbar';
import { API_ACCESS_URL,generateAuthToken } from '../modals/Constants';
import { getStorage } from '../modals/Storage';

function MyInvestments(){
    const navigate = useNavigate();
    const listViewRef = useRef();
    const [pageConst, setConstants] = useState({
        pageTitle: "My Investments",
        isLoadingShow: false,
        pageCount: 1,
        recordList: [],
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn1"){
          navigate('/withdraw', { replace: false });
        }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const onScroll = () => {
      if (listViewRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listViewRef.current;
        const isNearBottom = scrollTop + clientHeight >= scrollHeight;

        if (isNearBottom) {
          let newPageVal = pageConst.pageCount + 1;
          setConstants(previousState => {
            return { ...previousState, pageCount: newPageVal }
          });
        }
      }
    }

    const updateRecordList = (data) => {
      let tempData = [];

      if(pageConst.recordList.length > 0){
        tempData = pageConst.recordList;
      }

      for (let i = 0; i < data.length; i++) {          
        tempData.push(
            <Link key={i} className="col-view txt-deco-n mg-t-15 br-5 bg-white ovf-hidden" to={"/investmentdetails?id="+data[i]['i_id']}>
              <div className="row-view pd-10-15">
                <img className="w-80-p" src={data[i]['i_media']} alt='image' />
                <div className='col-view mg-l-20'>
                  <span className='cl-black ft-sz-18'>{data[i]['i_title']}</span>
                  <span className='cl-grey ft-sz-12 mg-t-5'>{data[i]['i_time_stamp']}</span>
                  
                  <p className='cl-grey mg-t-10'>Price: ₹{data[i]['i_price']}</p>
                  <p className='cl-green'>Profit: ₹{data[i]['i_profit']}</p>
                </div>
              </div>

              <div className={`w-100 row-view sb-view pd-5-10 ${data[i]['i_status']=='closed' ? 'bg-red' : 'bg-blue-grad'}`}>
                <p className='cl-white'>{data[i]['i_status']=='closed' ? 'Closed' : 'Open'}</p>
                <p className='cl-white'>View Details &#10095;</p>
              </div>
              
            </Link>)
        };

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }

    function getTransactions(pageCount){
        const requestAPI = async (url) => {
          try {
            const res = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Route': 'route-myinvestments',
                'AuthToken': generateAuthToken(Number(getStorage("uid"))),
              }
            });

            const data = await res.json();
            updateLoadingStatus(false);

            if(data.status_code="success"){
              updateRecordList(data.data); 
            }

          } catch (error) {
            updateLoadingStatus(false);
          }

        }

        updateLoadingStatus(true);
        requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PAGE_NUM="+pageCount);
    }
  
    useEffect(() => {
      getTransactions(pageConst.pageCount);
      const listInnerElement = listViewRef.current;

      if (listInnerElement) {
        listInnerElement.addEventListener("scroll", onScroll);

        // Clean-up
        return () => {
          listInnerElement.removeEventListener("scroll", onScroll);
        };
      }
    }, [pageConst.pageCount]);

    return (
      <div className="v-center bg-black">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-primary" ref={listViewRef}>
          <LoadingDialog intentData={pageConst}/>

          <div className="col-view br-right-t br-left-t mg-b-15">

            <div className="col-view pd-10-15 bg-blue-grad">
              <div className="col-view mg-l-10">
                <p className="ft-sz-25 cl-white">My Investments</p>
                <p className="ft-sz-14 cl-white">Here all open and closed investmenst are showing.</p>
              </div>
            </div>

            <div className='col-view pd-5-15'>
              <div className={`col-view ${pageConst.recordList.length <=0 ? 'hide-v' : ''}`}>
                {pageConst.recordList}
              </div>

              <div className={`w-100 v-center cl-l-white mg-t-30 ${pageConst.recordList.length > 0 ? 'hide-v' : ''}`}>You have no investments</div>
            </div>

          </div>

          <BottomNavbar activeBar="myinvestments"/>
        </div>
      </div>
    );
}

export default MyInvestments;