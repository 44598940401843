import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL } from '../modals/Constants';
import { getStorage } from '../modals/Storage';

function DeviceManager(){
    const navigate = useNavigate();
    const [showZoomAnimation, setZoomAnimation] = useState(false);
    const [pageConst, setConstants] = useState({
        pageTitle: "DeviceManager",
        isLoadingShow: false,
        recordList: [],
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn1"){
          navigate('/withdraw', { replace: false });
        }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateRecordList = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {          
            tempData.push(
              <div key={i} className="col-view sb-view pd-10-15 bg-l-grey br-5 mg-t-15">
                <div className='ft-sz-13'>{data[i]['a_time_stamp']}</div>
                <div className='ft-sz-18 ft-wgt-500'>{data[i]['a_device_info']}</div>
                <div className='row-view sb-view'>
                  <div className='ft-sz-13'>IP: {data[i]['a_device_ip']}</div>
                  <div className="v-center pd-10-15 br-10 ft-sz-15 cl-white ft-wgt-500 bg-blue">Delete</div>
                </div>
              </div>)
        };

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }

    function getGameRecords(){
        const fecthApiData = async (url) => {
            try {
              const res = await fetch(url, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Route': 'route-devicemanager-records',
                  'AuthToken': getStorage("secret"),
                }
              });
              const data = await res.json();
              updateLoadingStatus(false);

              if(data.status_code="success"){
                updateRecordList(data.data); 
                setZoomAnimation(true);
              }
            } catch (error) {
              updateLoadingStatus(false);
            }
        };

        updateLoadingStatus(true);
        fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid"));
    }
  
    useEffect(() => {
        getGameRecords();
    }, []);

    return (
      <div className="v-center bg-black ovf-hidden">
        <LoadingDialog intentData={pageConst}/>
        <div className='h-100vh res-wth bg-primary'>
          <div className={`pr-v ovf-scrl-y hide-sb ${showZoomAnimation ? 'zoomAnimView' : ''}`}>
           <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>

           <div className="col-view pd-5-15 mg-b-15 mg-t-45">
            {pageConst.recordList}
           </div>
          </div>
        </div>
      </div>
    );
}

export default DeviceManager;