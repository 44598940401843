import React,{useRef, useState, useEffect} from 'react'
import { setStorage,getStorage,deleteStorage } from '../modals/Storage';
import { API_ACCESS_URL,AVATARS_ARR,openNewPage } from '../modals/Constants';
import { Link,useNavigate } from 'react-router-dom';
import BottomNavbar from '../other-components/BottomNavbar';
import NoticeDialog from '../dialogs/NoticeDialog';
import ToastDialog from '../dialogs/ToastDialog';

function Home(){
  const navigate = useNavigate();
  const [isLoadingShow, setLoadingShow] = useState(false);
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  const delay = 2500;
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const [pageConst, setConstants] = useState({
    accountBalance: 0,
    isDataLoaded: false,
    toastDialogShow: false,
    toastMessage: "",
    isAccountLinked: "true",
    investmentList: [],
    sliderImages: [],
    winnersRecordList: [],
  });

  const [noticeDialogConst, setNoticeDialogConst] = useState({
    noticeTitle: "",
    noticeNote: "",
    noticeDialogShow: false,
  })

  const onInvestmentPurchase = (data) =>{
    setStorage("investmentItemArr", JSON.stringify(data));
    navigate('/investmentpurchase', { replace: false });
  }

  const updateToastDialogState = (data,msg) => {
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const refreshData = () =>{
    setConstants(previousState => {
      return { ...previousState, isDataLoaded: false }
    });
  }

  const updateSliderImagesArr = (data) => {
    if (data.length > 0) {
      setConstants((previousState) => {
        return { ...previousState, sliderImages: data };
      });
    }
  }

  const setInvestmentList = (data) =>{
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div key={i} className="col-view mg-t-20 pd-0-15" onClick={() => onInvestmentPurchase(data[i])}>
          <div className='row-view'>
            <img src={data[i]['i_media']} className="w-80-p" alt="icon"/>
  
            <div className='col-view mg-l-10'>
             <span className='cl-l-white ft-sz-18'>{data[i]['i_name']}</span>
             <span className='cl-l-white ft-sz-14 mg-t-5'>{data[i]['i_description']}</span>
             <span className="ft-sz-18 mg-t-10 cl-primary-txt">₹{data[i]['i_price']}</span>
            </div>
          </div>

          <span className="w-100 txt-a-center cl-white ft-sz-18 pd-5-15 mg-t-15 br-10 bg-blue-grad">Buy Now</span>
        </div>
      );
    }
    
    setConstants(previousState => {
      return { ...previousState, investmentList: tempData }
    });
  }

  const showNotice = (data) =>{

    if(data.length > 0){
      setNoticeDialogConst(previousState => {
        return { ...previousState, noticeTitle: data[0]['notice_title'] }
      });

      setNoticeDialogConst(previousState => {
        return { ...previousState, noticeNote: data[0]['notice_note'] }
      });

      setNoticeDialogConst(previousState => {
        return { ...previousState, noticeDialogShow: true }
      });
    }
    
  }

  const updateNoticeDialogState = (data) => {
    setNoticeDialogConst(previousState => {
      return { ...previousState, noticeDialogShow: data }
    });
  }

  function getAccountInfo(){

    const fecthApiData = async (url) => {
      
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-account-info',
            'AuthToken': getStorage("secret"),
          }
        });
        const data = await res.json();
        
        setLoadingShow(false);

        if(data.status_code=="success"){  
          let accountAvatarId = data.data[0]['account_avatar_id'];
          let accountUserName = data.data[0]['account_user_name'];
          let accountBalance = data.data[0]['account_balance'];
          let accountWinningBalance = data.data[0]['account_w_balance'];
          let accountCommissionBalance = data.data[0]['account_c_balance'];
          let accountLevel = data.data[0]['account_level'];
          let accountGoldBondId = data.data[0]['account_gold_bond_id'];
          let account_bankaccount_linked = data.data[0]['account_bankaccount_linked'];

          let service_app_status = data.data[0]['service_app_status'];
          let service_min_recharge = data.data[0]['service_min_recharge'];
          let service_min_withdraw = data.data[0]['service_min_withdraw'];
          let service_recharge_option = data.data[0]['service_recharge_option'];
          let service_telegram_url = data.data[0]['service_telegram_url'];
          let service_payment_url = data.data[0]['service_payment_url'];
          let service_livechat_url = data.data[0]['service_livechat_url'];
          let service_app_download_url = data.data[0]['service_app_download_url'];
          
    
          setStorage("avatarId", accountAvatarId);
          setStorage("uname", accountUserName);
          setStorage("balance", accountBalance);
          setStorage("cbalance", accountCommissionBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("accountLevel", accountLevel);
          setStorage("accountGoldBondId", accountGoldBondId);
          setStorage("minwithdraw", service_min_withdraw);
          setStorage("minrecharge", service_min_recharge);
          setStorage("rechargeoptions", service_recharge_option);
          setStorage("telegramURL", service_telegram_url);
          setStorage("liveChatURL", service_livechat_url);
          setStorage("paymentURL", service_payment_url);
          setStorage("appDownloadURL", service_app_download_url);
          setStorage("winnersArr", JSON.stringify(data.winnersArr));
          setConstants({...pageConst, accountBalance: accountBalance, isDataLoaded: true, isAccountLinked: account_bankaccount_linked});

          if(service_app_status=="OFF"){
            navigate('/um', { replace: true });
          }else{
            updateWinnersRecords();
            showNotice(data.noticeArr);
            setInvestmentList(data.investmentArr);
            updateSliderImagesArr(data.slideShowList);
          }
        }else if(data.status_code=="account_progress"){
          setStorage("registrationFee",data.data[0].account_registration_fee);
          setStorage("paymentURL",data.data[0].service_payment_url);

          navigate('/accountprogress', { replace: true });
        }else if(data.status_code=="authorization_error"){
          if(deleteStorage()){
            navigate('/login', { replace: true });
          }
        }
        
      } catch (error) {
        setLoadingShow(false);
      }
    };

    if(!isLoadingShow && !pageConst.isDataLoaded){
      setLoadingShow(true);
      fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid"));
    }

  }

  const getRandomNum = (min, max, type) => {
    if(type=="multi"){
      return Math.round((Math.random()*(max-min)+min)/10)*10;
    }else{
      return Math.floor(Math.random() * (max - min) + min);
    }
  }

  const updateWinnersRecords = () =>{
    let tempData = [];
    
    for (let i = 0; i < 26; i++) {
      let randNum = getRandomNum(105,905, '');
      let randWinAmount = getRandomNum(10,15000, '');

      function getRandomNumber(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
      }

      let randomNumber = getRandomNumber(0,AVATARS_ARR.length-1);

      tempData.push(
        <div key={randWinAmount} className='v-center mg-r-50'>
          <div className="h-w-20 ovf-hidden br-50 mg-l-15">
            <img src={require("../icons/avatar_icon.png")} className="h-20-p" alt="icon" />
          </div>
          
          <div className='mg-l-5'>******{randNum} <img src={require("../icons/buy_up_arrow_icon.png")} className="h-20-p" alt="icon" /></div>
          <span className='mg-l-5 cl-green'>{randWinAmount} rs</span>
        </div>);
    }

    setConstants(previousState => {
      return { ...previousState, winnersRecordList: tempData }
    });
    
  }

  useEffect(() => {

    if(getStorage("uid")){
      getAccountInfo();
      resetTimeout();
    }else{
      deleteStorage();
      navigate('/login', { replace: true });
    }

    if (pageConst.sliderImages.length > 0) {
      timeoutRef.current = setTimeout(
        () =>
          setSlideShowIndex((prevIndex) =>
            prevIndex === pageConst.sliderImages.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      );
    }

    return () => {
      resetTimeout();
    }
  }, [pageConst.sliderImages, pageConst.investmentList, slideShowIndex]);

  return (
    <div className="v-center bg-black">

      <div className="h-100vh res-wth bg-primary ovf-scrl-y hide-sb">
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
        <NoticeDialog intentData={noticeDialogConst} updateState={updateNoticeDialogState} />

        <div className='ps-rl h-40-p w-100 leaderboard-view row-view bg-l-blue ovf-hidden'>
          <div className='row-view container white-sp-nw'>{pageConst.winnersRecordList}</div>
        </div>

        <div className={`w-100 pd-10 ${pageConst.isAccountLinked=='true' ? 'hide-v' : ''}`}>
          <div className={`pd-5-10 br-5 bg-l-blue ${pageConst.isAccountLinked=='true' ? 'hide-v' : ''}`}>
            <p className='ft-sz-18'>No BankCard Found</p>
            <p className='ft-sz-14 mg-t-5'>BankAccount is not Linked! Please add your BankCard to receive commissions.</p>
          </div>
        </div>

        <div className='zoomAnimView'>
        <div className={`w-100 slideshow`}>
          <div
            className="slideshowSlider"
            style={{
              transform: `translate3d(${-slideShowIndex * 100}%, 0, 0)`,
            }}
          >
            {pageConst.sliderImages.map((data, index) => (
              <div className={`slide br-5`} key={index} onClick={() => openNewPage(data['slider_action'])}>
                <img className={`w-100 h-150-p br-5 obj-f-contain`} src={data['slider_img']} />
              </div>
            ))}
          </div>
        </div>

        <iframe src="https://goldbroker.com/widget/historical/XAU?height=500&currency=INR" width="100%" height="350" style={{'border': '0', 'overflow': 'hidden'}}></iframe>

        <div className="col-view min-h w-100 pd-10 mg-b-10em">
            <div className="col-view br-right-t br-left-t pd-10-15 bg-blue-grad">
              <span className='cl-white ft-sz-20'>Invest In Gold</span>
              <span className='cl-l-white ft-sz-16'>Showing popular Gold Options</span>
            </div>

            {pageConst.investmentList}
        </div>

        </div>

        <BottomNavbar activeBar="home"/>
      </div>
    </div>
  );
}

export default Home;