import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import '../../MainStyle.css';
import BottomNavbar from '../other-components/BottomNavbar';
import { API_ACCESS_URL,getURLParam,generateAuthToken } from '../modals/Constants';
import { getStorage } from '../modals/Storage';

function InvestmentDetails(){
    const navigate = useNavigate();
    const [pageConst, setConstants] = useState({
        pageTitle: "Investment Details",
        isLoadingShow: false,
        recordList: [],
    });

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){
        navigate('/withdraw', { replace: false });
      }
    }

    const onInvestmentSell = () =>{
      navigate('/investmentsell?id='+getURLParam('id'), { replace: false });
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateRecordList = (data) => {
        console.log(data);

      let tempData = [];

      if(pageConst.recordList.length > 0){
        tempData = pageConst.recordList;
      }

      for (let i = 0; i < data.length; i++) {          
        tempData.push(
            <div key={i} className="col-view mg-t-15 br-5 bg-white ovf-hidden">
              <div className="row-view pd-10-15">
                <img className="w-80-p" src={data[i]['i_media']} alt='image' />
                <div className='col-view mg-l-20'>
                  <span className='ft-sz-18'>{data[i]['i_title']}</span>
                  <span className='ft-sz-12 mg-t-5'>{data[i]['i_time_stamp']}</span>
                  
                  <p className='mg-t-10'>Price: ₹{data[i]['i_price']}</p>
                </div>
              </div>

              <div className="col-view pd-10-15">
                <div className="row-view sb-view mg-t-10">
                  <p>Profit:</p>
                  <p className='cl-green'>₹{data[i]['i_profit']}</p>
                </div>

                <div className="row-view sb-view mg-t-10">
                  <p>Total Size:</p>
                  <p>{data[i]['i_size']} gm</p>
                </div>

                <div className="row-view sb-view mg-t-10">
                  <div className='col-view'>
                    <p>Commission:</p>
                    <p>(Everyday)</p>
                  </div>
                  <p>₹{data[i]['i_commission']}</p>
                </div>

                <div className="row-view sb-view mg-t-10">
                  <p>Days Left:</p>
                  <p className='cl-red'>{data[i]['i_days_left']}{Number(data[i]['i_days_left']) > 1 ? " days" : " day"}</p>
                </div>

                <div className="row-view sb-view mg-t-10">
                  <p>Bought:</p>
                  <p>{data[i]['i_time_stamp']}</p>
                </div>

                <div className="row-view sb-view mg-t-10">
                  <div className='col-view'>
                    <p>Last:</p>
                    <p>(Updated)</p>
                  </div>
                  <p className='cl-green'>{data[i]['i_last_update']}</p>
                </div>
              </div>

              <div className={`w-100 pd-10 ${data[i]['i_status']=='closed' ? 'hide-v' : ''}`} onClick={() => onInvestmentSell()}>
                <div className="w-100 v-center cl-white ft-sz-18 pd-5-15 mg-t-15 br-10 bg-red">Sell Now</div>
              </div>

              <div className={`w-100 row-view sb-view pd-5-10 ${data[i]['i_status']=='closed' ? 'bg-red' : 'bg-blue-grad'}`}>
                <p className='cl-white'>Status:</p>
                <p className='cl-white'>{data[i]['i_status']=='closed' ? 'Closed' : 'Open'}</p>
              </div>
              
            </div>)
        };

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }

    function getInvestmentDetails(investmentId){
        const requestAPI = async (url) => {
          try {
            const res = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Route': 'route-investmentdetails',
                'AuthToken': generateAuthToken(Number(getStorage("uid"))),
              }
            });

            const data = await res.json();
            updateLoadingStatus(false);

            if(data.status_code="success"){
              updateRecordList(data.data); 
            }

          } catch (error) {
            updateLoadingStatus(false);
          }

        }

        updateLoadingStatus(true);
        requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&INVESTMENT_ID="+investmentId);
    }
  
    useEffect(() => {
      if(getURLParam('id')!=null && getURLParam('id')!=""){
        let investmentId = getURLParam('id');
        getInvestmentDetails(investmentId);
      }else{
        navigate('/home', { replace: true });
      }
    }, []);

    return (
      <div className="v-center bg-black">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-primary">
          <LoadingDialog intentData={pageConst}/>
          <TopBar intentData={pageConst} multiBtn={false} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>

          <div className="col-view mg-t-55 mg-b-15">

            <div className='col-view pd-5-15'>
             {pageConst.recordList}
            </div>

          </div>

          <BottomNavbar activeBar="myinvestments"/>
        </div>
      </div>
    );
}

export default InvestmentDetails;