import React, { useEffect, useState, useRef } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import TopBar2 from '../other-components/TopBar2';
import ToastDialog from '../dialogs/ToastDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL,IP_ACCESS_URL,generateAuthToken,getURLParam } from '../modals/Constants';
import { setStorage,getStorage } from '../modals/Storage';
import UploadShowImage from '../other-components/UploadShowImage';

function Register(){
  const navigate = useNavigate();
  const Ref = useRef(null);
  const [resendOTPTimeLeft, setResendOTPTime] = useState(0);
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [inAadhaarCardImage, setAadhaarCardImage] = useState(null);
  const [inPanCardImage, setPanCardImage] = useState(null);
  const [inBankPassbookImage, setBankPassbookImage] = useState(null);

  const [pageConst, setConstants] = useState({
      pageTitle: "Register",
      inMobileNum: "",
      inPassword: "",
      inConfirmPassword: "",
      inInviteCode: "",
      inAadhaarCard: "",
      inPanCard: "",
      inPassBook: "",
      inVerificationCode: "",
      isLoadingShow: false,
      toastDialogShow: false,
      toastTimeAvail: 7,
      toastMessage: "",
      isSessionExist: true,
      isOTPSending: false,
      resendOTPTimeLimit: 60,
  });

  const topBarClickAction = (data) =>{
    if(data=="multiBtn2"){
      navigate('/login', { replace: false });
    }
  }

  const onImageUpdate = (data,image) =>{
    if(data=="Upload Aadhaar Card"){
      setAadhaarCardImage(image);
    }else if(data=="Upload PanCard"){
      setPanCardImage(image);
    }else{
      setBankPassbookImage(image);
    }
  }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateOTPSendingStatus = (data) =>{
      setConstants(previousState => {
        return { ...previousState, isOTPSending: data }
      });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const isMobileNumValidate = (mobilenum) =>{
      if(mobilenum != "" && mobilenum != undefined && mobilenum.length == 10){
        return true;
      }else{
        return false;
      }
    }

    const isPasswordValidate = (password,confirmPassword) =>{
      if(password!="" && password != undefined && password.length >= 6 && password==confirmPassword){
        return true;
      }else{
        return false;
      }
    }

    const checkForInputVal = (mobilenum,password,confirmPassword,verificationCode,aadhaarcard,pancard,passbook) =>{
      if(isMobileNumValidate(mobilenum) && isPasswordValidate(password,confirmPassword) && verificationCode.length > 4 && aadhaarcard.length == 12 && pancard.length == 10 && passbook.length > 8){
        setInValCorrect(true);
      }else{
        setInValCorrect(false);
      }
    }

    const onInputValChange = (source,data) =>{
      if(source=="mobile"){
        checkForInputVal(data, pageConst.inPassword, pageConst.inConfirmPassword, pageConst.inVerificationCode, pageConst.inAadhaarCard, pageConst.inPanCard, pageConst.inPassBook);

        setConstants(previousState => {
          return { ...previousState, inMobileNum: data }
        });
      }

      if(source=="password"){
        checkForInputVal(pageConst.inMobileNum, data, pageConst.inConfirmPassword, pageConst.inVerificationCode, pageConst.inAadhaarCard, pageConst.inPanCard, pageConst.inPassBook);

        setConstants(previousState => {
          return { ...previousState, inPassword: data }
        });
      }

      if(source=="confirmPassword"){
        checkForInputVal(pageConst.inMobileNum, pageConst.inPassword, data, pageConst.inVerificationCode, pageConst.inAadhaarCard, pageConst.inPanCard, pageConst.inPassBook);

        setConstants(previousState => {
          return { ...previousState, inConfirmPassword: data }
        });
      }

      if(source=="verificationCode"){
        checkForInputVal(pageConst.inMobileNum, pageConst.inPassword, pageConst.inConfirmPassword, data, pageConst.inAadhaarCard, pageConst.inPanCard, pageConst.inPassBook);

        setConstants(previousState => {
          return { ...previousState, inVerificationCode: data }
        });
      }

      if(source=="aadhaarcard"){
        checkForInputVal(pageConst.inMobileNum, pageConst.inPassword, pageConst.inConfirmPassword, pageConst.inVerificationCode, data, pageConst.inPanCard, pageConst.inPassBook);

        setConstants(previousState => {
          return { ...previousState, inAadhaarCard: data }
        });
      }

      if(source=="pancard"){
        checkForInputVal(pageConst.inMobileNum, pageConst.inPassword, pageConst.inConfirmPassword, pageConst.inVerificationCode, pageConst.inAadhaarCard, data, pageConst.inPassBook);

        setConstants(previousState => {
          return { ...previousState, inPanCard: data.toUpperCase() }
        });
      }

      if(source=="passbook"){
        checkForInputVal(pageConst.inMobileNum, pageConst.inPassword, pageConst.inConfirmPassword, pageConst.inVerificationCode, pageConst.inAadhaarCard, pageConst.inPanCard, data);

        setConstants(previousState => {
          return { ...previousState, inPassBook: data }
        });
      }
    }

    const validateMyIP = (route) =>{
      updateLoadingStatus(true);

      const requestAPI = async (url) => {
        try {
          const res = await fetch(url, {
            method: 'GET',
          });

          const data = await res.json();
          if(data.ip!=""){
            if(route=="OTP"){
              return sendVerificationCode(data.ip);
            }else{
              return validateSignup(data.ip);
            }
          }else{
            return "IPUNKNOWN";
          }

        } catch (error) {
          return "IPOUTERROR";
        }
      };

      requestAPI(IP_ACCESS_URL);
    }

    const validateSignup = (ip) => {
      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'route': 'route-create-account',
              'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
            },
            body: formData,
          });

          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code=="invalid_otp"){
            updateToastDialogState(true,"OTP is incorrect ! Please enter correct OTP!");
          }else if(data.status_code=="invalid_refer_code"){
            updateToastDialogState(true,"Invite Code is incorrect ! Please enter correct Invite Code!");
          }else if(data.status_code=="success"){
            setStorage("uid",data.data[0].account_id, 30);
            setStorage("mobile",data.data[0].account_mobile_num, 30);
            setStorage("balance",data.data[0].account_balance, 30);
            setStorage("secret",data.data[0].auth_secret_key, 30);
            navigate('/home', { replace: true });
          }else if(data.status_code=="password_error"){
            updateToastDialogState(true,"Password not correct! try again");
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(isInputValCorrect){
        let fd = new FormData();
        fd.append("SIGNUP_PHONE", pageConst.inMobileNum);
        fd.append("SIGNUP_PASSWORD", pageConst.inPassword);
        fd.append("SIGNUP_OTP", pageConst.inVerificationCode);
        fd.append("SIGNUP_REFER_CODE", pageConst.inInviteCode);
        fd.append("SIGNUP_AADHAARCARD", pageConst.inAadhaarCard);
        fd.append("SIGNUP_PANCARD", pageConst.inPanCard);
        fd.append("SIGNUP_PASSBOOK", pageConst.inPassBook);
        fd.append("SIGNUP_AADHAARCARD_IMG", inAadhaarCardImage);
        fd.append("SIGNUP_PANCARD_IMG", inPanCardImage);
        fd.append("SIGNUP_PANCARD_IMG", inPanCardImage);
        fd.append("SIGNUP_PASSBOOK_IMG", inBankPassbookImage);

        // const formData = {  SIGNUP_AADHAARCARD_IMG: inAadhaarCardImage, SIGNUP_PANCARD_IMG: inPanCardImage};
        requestAPI(API_ACCESS_URL,fd);
      }else{
        updateLoadingStatus(false);
        updateToastDialogState(true,"Oops! Entered details was wrong!");
      }
    }

    const getTimeRemaining = (e) => {
      const total = Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      return {
          total, seconds
      };
    }

    const startCountDownTimer = (e) =>{
      let { total, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        setResendOTPTime((seconds > 9 ? seconds : '0' + seconds))
      }
    }

    const getDeadTime = (e) => {
      let deadline = new Date();

      deadline.setSeconds(deadline.getSeconds() + e);
      return deadline;
    }

    
    const clearTimer = (e) => {
      setResendOTPTime(30);

      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        startCountDownTimer(e);
      }, 1000)
      Ref.current = id;
    }

    const sendVerificationCode = (ip) =>{

      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-send-sms',
              'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          updateLoadingStatus(false);
          updateOTPSendingStatus(false);

          if(data.status_code=="otp_error"){
            updateToastDialogState(true,"We failed to send OTP ! Please try again!");
          }else if(data.status_code=="already_registered"){
            updateToastDialogState(true,"Sorry, This account is already registered !");
          }else if(data.status_code=="mobile_num_error"){
            updateToastDialogState(true,"Mobile Number is invalid !");
          }else if(data.status_code=="otp_limit_error"){
            updateToastDialogState(true,"You've used daily limited OTP ! Please try later!");
          }else if(data.status_code=="success"){
            setStorage("otptimeout",getDeadTime(pageConst.resendOTPTimeLimit), 30);
            clearTimer(getDeadTime(pageConst.resendOTPTimeLimit));
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateOTPSendingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(isMobileNumValidate(pageConst.inMobileNum)){
        if(resendOTPTimeLeft > 0){
          updateLoadingStatus(false);
          updateToastDialogState(true,"Please after sometime!");
        }else if(pageConst.isOTPSending==false){
          updateOTPSendingStatus(true);
          const formData = { MOBILE: pageConst.inMobileNum,PURPOSE: "SIGNUP"};
          requestAPI(API_ACCESS_URL,formData);
        }
      }else{
        updateLoadingStatus(false);
        updateToastDialogState(true,"Mobile Number is incorrect!");
      }
    }

    useEffect(() => {
      if(getStorage("uid")){
        navigate('/home', { replace: true });
      }else{

        setConstants({...pageConst, isSessionExist: false});

        if(getStorage("otptimeout")){
          let { total, seconds } = getTimeRemaining(getStorage("otptimeout"));
          if(seconds > 0){
            clearTimer(getDeadTime(seconds));
          }
        }

        if(getURLParam('c')!=null && getURLParam('c')!=""){
          let referCode = getURLParam('c');
          setConstants(previousState => {
            return { ...previousState, inInviteCode: referCode }
          });
        }
        
      }
    }, []);

    return (
      <div className={`v-center bg-black ${pageConst.isSessionExist == true ? 'hide-v' : ''}`}>
        <div className="res-wth bg-primary">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className='pr-v ovf-scrl-y hide-sb zoomAnimView'>
            <TopBar2 intentData={pageConst} multiBtn1="" updateState={topBarClickAction}/>

            <div className='col-view w-100 v-center pd-5-15 mg-b-15 mg-t-70'>

                <img className="h-120-p br-5" src={require('../icons/logo.png')} />

                <div className='w-100 col-view pd-10 mg-t-15'>
                    
                    <div className='row-view'>
                      <img className="h-w-22" src={require('../icons/phone_icon_blue.png')} />
                      <label className='cl-l-white mg-l-10'>Mobile Number</label>
                    </div>

                    <input type="number" maxLength={10} className='cutm-inp ft-sz-18 inp-ph-color-l-white mg-t-10 pd-15 br-5 bg-purple' placeholder='Enter Mobile Number' autoComplete="new-password" onChange={e => onInputValChange('mobile',e.target.value)}></input>
                
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                    
                    <div className='row-view'>
                      <img className="h-w-22" src={require('../icons/lock_icon_blue.png')} />
                      <label className='cl-l-white mg-l-10'>Password</label>
                    </div>

                    <input type="password" className='cutm-inp ft-sz-18 inp-ph-color-l-white mg-t-10 pd-15 br-5 bg-purple' placeholder='Password (≥6 characters)' autoComplete="new-password" onChange={e => onInputValChange('password',e.target.value)}></input>
                
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                    
                    <div className='row-view'>
                      <img className="h-w-22" src={require('../icons/lock_icon_blue.png')} />
                      <label className='cl-l-white mg-l-10'>Password</label>
                    </div>

                    <input type="password" className='cutm-inp ft-sz-18 inp-ph-color-l-white mg-t-10 pd-15 br-5 bg-purple' placeholder='Cofirm Password' autoComplete="new-password" onChange={e => onInputValChange('confirmPassword',e.target.value)}></input>
                
                </div>

                <div className='w-100 pd-10 mg-t-10'>
                  <div className='line-hv-grey bg-l-white-2'></div>
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                    
                    <div className='row-view'>
                      <img className="h-w-22" src={require('../icons/invitation_code_icon_blue.png')} />
                      <label className='cl-l-white mg-l-10'>Aadhaar Card</label>
                    </div>

                    <input type="number" maxLength={12} className='cutm-inp ft-sz-18 inp-ph-color-l-white mg-t-10 pd-15 br-5 bg-purple' placeholder='Aadhaar Card' value={pageConst.inAadhaarCard} autoComplete="new-password" onChange={e => onInputValChange('aadhaarcard',e.target.value)}></input>
                
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                  <UploadShowImage data="Upload Aadhaar Card" onImageUpdate={onImageUpdate}/>
                </div>

                <div className='w-100 pd-10 mg-t-10'>
                  <div className='line-hv-grey bg-l-white-2'></div>
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                    
                    <div className='row-view'>
                      <img className="h-w-22" src={require('../icons/invitation_code_icon_blue.png')} />
                      <label className='cl-l-white mg-l-10'>Pan Card</label>
                    </div>

                    <input type="text" maxLength={10} className='cutm-inp ft-sz-18 inp-ph-color-l-white mg-t-10 pd-15 br-5 bg-purple' placeholder='Pan Card' value={pageConst.inPanCard} autoComplete="new-password" onChange={e => onInputValChange('pancard',e.target.value)}></input>
                
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                  <UploadShowImage data="Upload PanCard" onImageUpdate={onImageUpdate}/>
                </div>

                <div className='w-100 pd-10 mg-t-10'>
                  <div className='line-hv-grey bg-l-white-2'></div>
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                    
                    <div className='row-view'>
                      <img className="h-w-22" src={require('../icons/invitation_code_icon_blue.png')} />
                      <label className='cl-l-white mg-l-10'>Bank Account</label>
                    </div>

                    <input type="text" maxLength={20} className='cutm-inp ft-sz-18 inp-ph-color-l-white mg-t-10 pd-15 br-5 bg-purple' placeholder='Account Number' value={pageConst.inPassBook} autoComplete="new-password" onChange={e => onInputValChange('passbook',e.target.value)}></input>
                
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                  <UploadShowImage data="Upload Bank Passbook" onImageUpdate={onImageUpdate}/>
                </div>

                <div className='w-100 col-view pd-10 mg-t-5'>
                    
                    <div className='row-view'>
                      <img className="h-w-22" src={require('../icons/invitation_code_icon_blue.png')} />
                      <label className='cl-l-white mg-l-10'>OTP</label>
                    </div>

                    <div className='w-100 row-view mg-t-10'>
                      <input type="number" className='cutm-inp ft-sz-18 inp-ph-color-l-white pd-15 br-5 bg-purple' placeholder='OTP' autoComplete="new-password" onChange={e => onInputValChange('verificationCode',e.target.value)}></input>
                      <div className='w-125-p pd-12 v-center ft-sz-18 br-5 cl-white bg-green' onClick={() => validateMyIP("OTP")}>{pageConst.isOTPSending ? 'Sending' : resendOTPTimeLeft > 0 ? resendOTPTimeLeft : 'OTP'}</div>
                    </div>
                
                </div>

                <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bg-blue-grad`} onClick={() => validateMyIP("REGISTER")}>
                  <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow==false ? 'hide-v' : ''}`}></div>
                  <span className={`${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Register</span>
                </div>

                <div className='w-100 v-center cl-white ft-sz-16 mg-t-20'>
                    <span>Already have an account?</span>
                    <Link className='txt-deco-n mg-l-10 cl-blue' to={"/LG"}>Log in</Link>
                </div>
            </div>

          </div>
        </div>
      </div>
    );
}

export default Register;