import React,{ useState, useEffect} from 'react'
import { setStorage,getStorage } from '../modals/Storage';
import { API_ACCESS_URL,getURLParam,generateAuthToken } from '../modals/Constants';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import ToastDialog from '../dialogs/ToastDialog';

function InvestmentSell(){
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    pageTitle: "Investment Sell",
    recordList: [],

    toastDialogShow: false,
    toastMessage: "",

    investmentName: "",
    investmentPrice: "",
    investmentDescription: "",
    investmentMaxLots: "",

    contractAmnt: 0,
    contracTotalAmnt: 0,
    contractNumLot: 1,
    isLoadingShow: false,
  });

  const topBarClickAction = (data) =>{
    if(data=="multiBtn1"){
      navigate('/withdraw', { replace: false });
    }
  }

  const updateLoadingDialog = (data) =>{
    setConstants(previousState => {
        return { ...previousState, isLoadingShow: data }
    });
  }

  const updateToastDialogState = (data,msg) => {
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const onSell = (data) =>{
    if(data=="true"){
        navigate('/sellsuccessful', { replace: true });
    }else{
        updateToastDialogState(true,data);
    }
  }

  const updateLotData = (data,amount) => {
      let updatedData = pageConst.contractNumLot + Number(data);

      if(updatedData >= 1 && updatedData <= pageConst.investmentMaxLots){
          let updatedContractAmount = updatedData * amount;

          setConstants(previousState => {
            return { ...previousState, contracTotalAmnt: updatedContractAmount }
          });

          setConstants(previousState => {
              return { ...previousState, contractNumLot: updatedData }
          });
      }
  }
  

  const confirmSell = () => {
        updateLoadingDialog(true);

        async function requestAPI(url,formData) {
  
            try {
              const res = await fetch(url, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Route': 'route-sell',
                  'AuthToken': getStorage("secret"),
                },
                body: JSON.stringify(formData),
              });

              const data = await res.json();
              updateLoadingDialog(false);
              
              if(data.status_code=="success"){
                setStorage("balance", data.account_balance, 30);
                onSell("true");
              }else if(data.status_code=="balance_error"){
                onSell("Your balance is insufficient ! Please recharge now !");
              }else if(data.status_code=="account_error"){
                onSell("Account Problem! Please try again!");
              }else if(data.status_code=="auth_error"){
                onSell("Authentication Error! Please login again!");
              }else{
                onSell("There was a technical issue! Please try again!");
              }
              
            } catch (error) {
                updateLoadingDialog(false);
                onSell("There was a technical issue! Please try again!");
            }
        };

        const formData = { USER_ID: getStorage("uid"),INVESTMENT_ID: getURLParam("id"),INVESTMENT_LOT: pageConst.contractNumLot};

        requestAPI(API_ACCESS_URL,formData);
  }

  const updateRecordList = (data) => {
    for (let i = 0; i < data.length; i++) {      
        setConstants(previousState => {
            return { ...previousState, investmentName: data[i]['i_title'] }
        });

        setConstants(previousState => {
            return { ...previousState, contracTotalAmnt: Number(data[i]['i_price']) }
        });

        setConstants(previousState => {
            return { ...previousState, investmentPrice: Number(data[i]['i_price_only']) }
        });

        setConstants(previousState => {
            return { ...previousState, investmentMaxLots: Number(data[i]['i_size']) }
        });

        setConstants(previousState => {
            return { ...previousState, contractNumLot: Number(data[i]['i_size']) }
        });

    };

  }

  function getInvestmentDetails(investmentId){
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-investmentdetails',
            'AuthToken': generateAuthToken(Number(getStorage("uid"))),
          }
        });

        const data = await res.json();
        updateLoadingDialog(false);

        if(data.status_code="success"){
          updateRecordList(data.data); 
        }

      } catch (error) {
        updateLoadingDialog(false);
      }

    }

    updateLoadingDialog(true);
    requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&INVESTMENT_ID="+investmentId);
  }


  useEffect(() => {

    if(getURLParam('id')!=null && getURLParam('id')!=""){
      let investmentId = getURLParam('id');
      getInvestmentDetails(investmentId);
    }else{
       navigate('/home', { replace: true });
    }

  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth bg-primary ovf-scrl-y hide-sb">
        <LoadingDialog intentData={pageConst}/>
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

        <div className='zoomAnimView'>
            <TopBar intentData={pageConst} multiBtn={false} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
            
            <div className='pd-15 mg-t-45'>
                <p className='cl-white ft-sz-18 pd-5'>{pageConst.investmentName}</p>

                  <div className='row-view sb-view h-50-p w-100 pd-10 br-10 mg-t-10 bg-l-white-2'>
                    <p className='cl-white ft-sz-20'>Price</p>
                    <p className='cl-white ft-sz-23'>₹{pageConst.contracTotalAmnt > 0 ? pageConst.contracTotalAmnt : Number(pageConst.investmentPrice)}</p>
                  </div>

                  <div className='col-view mg-t-15 w-100'>
                    <p className='cl-white'>Size</p>

                    <div className='row-view sb-view mg-t-5'>
                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('-5',Number(pageConst.investmentPrice))}>-5</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('-1',Number(pageConst.investmentPrice))}>-1</div>
                        </div>

                        <h3 className='cl-golden ft-sz-20'>{pageConst.contractNumLot} gm</h3>

                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('1',Number(pageConst.investmentPrice))}>+1</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('5',Number(pageConst.investmentPrice))}>+5</div>
                        </div>
                        
                    </div>
                  </div>

                  <div className='w-100 mg-t-15 mg-b-10'>
                    <div className='line-hv-grey bg-l-white-2'></div>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-15'>
                    <p className='ft-sz-15 cl-white'>Total Size:</p>
                    <p className='ft-sz-15 cl-white'>{pageConst.contractNumLot} gm</p>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-20'>
                    <p className='ft-sz-17 cl-white'>Total Amount:</p>
                    <p className='ft-sz-17 cl-white'>₹{(pageConst.contracTotalAmnt ? pageConst.contracTotalAmnt : Number(pageConst.investmentPrice))}</p>
                  </div>

                  <div className='w-100 h-50-p ft-sz-20 pd-10 br-10 cl-white v-center mg-t-30 bg-red' onClick={()=>confirmSell()}>
                    <span>Confirm Sell</span>
                  </div>

                  <div className='v-center mg-t-10'>
                    <img src={require("../icons/secure_icon_white.png")} className="h-w-15" alt="icon" />
                    <span className='cl-white ft-sz-14 mg-l-5'>100% Safe & Secure</span>
                  </div>

            </div>

        </div>

        
      </div>
    </div>
  );
}

export default InvestmentSell;