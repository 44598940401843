import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import BottomNavbar from '../other-components/BottomNavbar';
import { getStorage, setStorage } from '../modals/Storage';
import { API_ACCESS_URL } from "../modals/Constants";

  function Invite() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
      gameTitle: "Invite",
      isLoadingShow: false,
      todaysEarning: 0,
      todaysInvite: 0,
      totalInvite: 0,
      totalIncome: 0,
      recordList: [],
  });

  const updateLoadingStatus = (data) => {
      setConstants(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
  }

  const updateRecordList = (data) => {
    let tempData = [];
    let dataList = data.data;

      for (let i = 0; i < dataList.length; i++) {          
          tempData.push(
            <div key={i} className="row-view sb-view w-100 mg-t-10">
              <div className="h-w-36 br-50 v-center cl-white bg-drk-blue">{dataList[i]['t_msg']=='Level 2' ? 'Lv2' : dataList[i]['t_msg']=='Level 2' ? 'Lv2' : 'Lv1'}</div>
              
              <div className="row-view sb-view w-85">
                <div className="col-view">
                  <span className="cl-white ft-sz-17">{dataList[i]['t_msg']!='Recharge Bonus' ? dataList[i]['t_msg']+'- Commission' : dataList[i]['t_msg']}</span>
                  <div className="row-view ft-sz-12 cl-l-grey mg-t-5">
                   <span>{dataList[i]['t_date']+' '+dataList[i]['t_time']}</span>
                   <span className="mg-l-15">from {dataList[i]['t_receive_from']}</span>
                  </div>
                </div>

                <span className='cl-white'>+₹{dataList[i]['t_amount']}</span>
              </div>

            </div>)

            if(dataList.length-1 > i){
              tempData.push(
                <div key={i+25} className="line-hv-grey mg-t-10"></div>
               )
            }
            
      };

      setConstants({...pageConst, todaysEarning: data['todays_income'], todaysInvite: data['todays_invite'], totalInvite: data['total_invite'], totalIncome: data['total_income']});
      setConstants(previousState => {
          return { ...previousState, recordList: tempData }
      });
  }


  function getCommissionRecords(){
      const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-commission-records',
            'AuthToken': getStorage("secret"),
          }
        });
        const data = await res.json();
        console.log(data);

          if(data.status_code="success"){
            setStorage("cbalance", data.total_income, 30);
            updateRecordList(data); 
          }
      } catch (error) {
      }
    };

    fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PAGE_NUM=1");
  }

  useEffect(() => {
    if(!getStorage("uid")){
      navigate('/LG', { replace: true });
    }else {
      getCommissionRecords();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth bg-primary ovf-scrl-y hide-sb">

      <div className='zoomAnimView'>
        <div className="row-view sb-view pd-20">
          <div className="col-view">
            <span className="cl-white ft-sz-13">Earned Income</span>
              <span className="cl-white ft-sz-25 ft-wgt-500">₹{getStorage("cbalance")}</span>
          </div>

          <Link className='txt-deco-n bg-blue cl-white pd-10-15 br-5' to={"/withdraw?M=C"}>Withdraw</Link>
        </div>

        <div className='w-100 g-v-3 pd-20'>
          <Link className='col-view v-center txt-deco-n pd-20-10 br-10 bg-orange'>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="currentColor" className="cl-white h-2p5-rem w-2p5-rem"><path d="M138.629 32.653a12.244 12.244 0 0 1 10.224 5.506l23.763 36.045a12.24 12.24 0 0 1-.71 14.45l-63.947 78.926a12.234 12.234 0 0 1-9.65 4.533 12.253 12.253 0 0 1-9.546-4.75L27.47 88.175a12.244 12.244 0 0 1-.938-13.595l20.563-35.784a12.244 12.244 0 0 1 10.616-6.143h80.919Zm16.591 57.143h-25.289l-19.707 55.535 45-55.535h-.004Zm-89.726 0H44.208l41.874 54.094-20.588-54.094Zm51.445 0H78.592l19.84 52.151 18.507-52.151ZM52.114 54.629 38.947 77.55h21.89l-8.723-22.922Zm91.217-2.6-9.058 25.522h25.882l-16.824-25.522Zm-45.372-6.254L80.624 77.552h34.666L97.96 45.775Zm-13.465-.881h-22.98l9.445 24.82 13.535-24.82Zm48.379 0h-21.444l12.991 23.82 8.453-23.82Z"></path></svg>
            <p className='cl-white mg-t-5'>Privilege</p>
          </Link>

          <Link className='col-view v-center txt-deco-n pd-20-10 br-10 bg-blue' to={"/allmembers"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="currentColor" className="cl-white h-2p5-rem w-2p5-rem"><path d="m34.354 150.2 28.633-33.408A54.12 54.12 0 0 0 74.73 122.3l-30.883 36.033h64.487v12.5H43.846a12.496 12.496 0 0 1-11.356-7.276 12.498 12.498 0 0 1 1.864-13.357ZM145.833 125v16.667H162.5v12.5h-16.667v16.666h-12.5v-16.666h-16.666v-12.5h16.666V125h12.5ZM91.667 29.167c23.012 0 41.666 18.654 41.666 41.666 0 23.013-18.654 41.667-41.666 41.667C68.654 112.5 50 93.846 50 70.833c0-23.012 18.654-41.666 41.667-41.666Zm0 12.5a29.167 29.167 0 1 0 0 58.333 29.167 29.167 0 0 0 0-58.333Z"></path></svg>
            <p className='cl-white mg-t-5'>Members</p>
          </Link>

          <Link className='col-view v-center txt-deco-n pd-20-10 br-10 bg-green' to={"/invitelink"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="currentColor" className="cl-white h-2p5-rem w-2p5-rem"><path d="M111.112 29.167a12.499 12.499 0 0 1 12.5 12.5v22.22a12.496 12.496 0 0 1-12.5 12.5h-4.862v9.492h27.442a18.75 18.75 0 0 1 18.729 17.867l.021.883-.005 15.509h5.896a12.5 12.5 0 0 1 12.5 12.5v22.225a12.499 12.499 0 0 1-12.5 12.5h-22.221a12.496 12.496 0 0 1-8.838-3.662 12.496 12.496 0 0 1-3.662-8.838v-22.225a12.499 12.499 0 0 1 12.5-12.5h3.825l.005-15.455-.03-.654a6.248 6.248 0 0 0-6.22-5.65l-68.138.004c-3.45.146-6.125 3.063-5.975 6.775l-.004 14.98h4.313a12.5 12.5 0 0 1 12.5 12.5v22.225a12.498 12.498 0 0 1-12.5 12.5H41.666a12.497 12.497 0 0 1-12.5-12.5v-22.225a12.499 12.499 0 0 1 12.5-12.5h5.408l.008-14.717a18.75 18.75 0 0 1 17.063-19.467l.879-.058c.264-.011.528-.017.792-.017H93.75v-9.491h-4.862a12.5 12.5 0 0 1-12.5-12.5V41.667a12.5 12.5 0 0 1 12.5-12.5h22.224ZM63.888 132.638H41.666v22.225h22.22v-22.225Zm94.445 0h-22.221v22.225h22.221v-22.225Zm-47.221-90.971H88.888v22.22h22.224v-22.22Z"></path></svg>
            <p className='cl-white mg-t-5'>My Link</p>
          </Link>
        </div>

        <div className='pd-20'>
          <div className="row-view sb-view pd-10-15 br-10 bg-l-grey">
            <div className="col-view">
              <span className="ft-sz-20 ft-wgt-500">{pageConst.todaysInvite}</span>
              <span className="ft-sz-16">Invited today</span>
            </div>
            
            <div className="col-view">
              <span className="ft-sz-20 ft-wgt-500">₹{pageConst.todaysEarning}</span>
              <span className="ft-sz-16">Today's income</span>
            </div>
          </div>
        </div>

        <div className="col-view v-center pd-20 mg-b-70">
          <div className="row-view sb-view w-100">
            <span className='cl-white'>Recent Record(s)</span>
            <Link className="txt-deco-n pd-5 cl-white ft-sz-15" to={"/recharge"}>More</Link>
          </div>

          <div className="col-view w-100 mg-t-15">
            {pageConst.recordList}
          </div>

        </div>
      </div>
      
      <BottomNavbar activeBar="invite"/>

      </div>
    </div>
  );
}

export default Invite;
