import React,{ useState, useEffect} from 'react'
import { setStorage,getStorage } from '../modals/Storage';
import { API_ACCESS_URL } from '../modals/Constants';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import ToastDialog from '../dialogs/ToastDialog';

function InvestmentPurchase(){
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    pageTitle: "Investment Details",
    investmentItemArr: [],

    toastDialogShow: false,
    toastMessage: "",

    contractAmnt: 0,
    contracTotalAmnt: 0,
    contractNumLot: 1,
    isLoadingShow: false,
  });

  const topBarClickAction = (data) =>{
    if(data=="multiBtn1"){
      navigate('/withdraw', { replace: false });
    }
  }

  const updateLoadingDialog = (data) =>{
    setConstants(previousState => {
        return { ...previousState, isLoadingShow: data }
    });
  }

  const updateToastDialogState = (data,msg) => {
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const onPurchase = (data) =>{
    if(data=="true"){
        navigate('/purchasesuccessful', { replace: true });
    }else{
        updateToastDialogState(true,data);
    }
  }

  const updateLotData = (data,amount) => {
      let updatedData = pageConst.contractNumLot + Number(data);

      if(updatedData >= 1 && updatedData < 21){
          let updatedContractAmount = updatedData * amount;

          setConstants(previousState => {
            return { ...previousState, contracTotalAmnt: updatedContractAmount }
          });

          setConstants(previousState => {
              return { ...previousState, contractNumLot: updatedData }
          });
      }
    }

    const confirmBuy = () => {
        updateLoadingDialog(true);

        async function requestAPI(url,formData) {
  
            try {
              const res = await fetch(url, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Route': 'route-purchase',
                  'AuthToken': getStorage("secret"),
                },
                body: JSON.stringify(formData),
              });

              const data = await res.json();
              updateLoadingDialog(false);
              
              if(data.status_code=="success"){
                setStorage("balance", data.account_balance, 30);
                onPurchase("true");
              }else if(data.status_code=="balance_error"){
                onPurchase("Your balance is insufficient ! Please recharge now !");
              }else if(data.status_code=="account_error"){
                onPurchase("Account Problem! Please try again!");
              }else if(data.status_code=="auth_error"){
                onPurchase("Authentication Error! Please login again!");
              }else{
                onPurchase("There was a technical issue! Please try again!");
              }
              
            } catch (error) {
                updateLoadingDialog(false);
              onPurchase("There was a technical issue! Please try again!");
            }
        };

        const formData = { USER_ID: getStorage("uid"),INVESTMENT_ID: pageConst.investmentItemArr['i_id'],INVESTMENT_LOT: pageConst.contractNumLot};

        requestAPI(API_ACCESS_URL,formData);
    }


  useEffect(() => {

    if(getStorage("investmentItemArr")){
        setConstants(previousState => {
            return { ...previousState, investmentItemArr: JSON.parse(getStorage("investmentItemArr")) }
        });
    }else{
      navigate('/home', { replace: true });
    }

  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth bg-primary ovf-scrl-y hide-sb">
        <LoadingDialog intentData={pageConst}/>
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

        <div className='zoomAnimView'>
            <TopBar intentData={pageConst} multiBtn={false} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
            
            <div className='pd-15 mg-t-45'>
                <p className='cl-white ft-sz-18 pd-5'>{pageConst.investmentItemArr['i_name']}</p>
                <p className='cl-white ft-sz-16 pd-5'>{pageConst.investmentItemArr['i_description']}</p>

                  <div className='row-view sb-view h-50-p w-100 pd-10 br-10 mg-t-10 bg-l-white-2'>
                    <p className='cl-white ft-sz-20'>Price</p>
                    <p className='cl-white ft-sz-23'>₹{pageConst.contracTotalAmnt ? pageConst.contracTotalAmnt : Number(pageConst.investmentItemArr['i_price'])}</p>
                  </div>

                  <div className='col-view mg-t-15 w-100'>
                    <p className='cl-white'>Size</p>

                    <div className='row-view sb-view mg-t-5'>
                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('-5',Number(pageConst.investmentItemArr['i_price']))}>-5</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('-1',Number(pageConst.investmentItemArr['i_price']))}>-1</div>
                        </div>

                        <h3 className='cl-golden ft-sz-20'>{pageConst.contractNumLot} gm</h3>

                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('1',Number(pageConst.investmentItemArr['i_price']))}>+1</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('5',Number(pageConst.investmentItemArr['i_price']))}>+5</div>
                        </div>
                        
                    </div>
                  </div>

                  <div className='w-100 mg-t-15 mg-b-10'>
                    <div className='line-hv-grey bg-l-white-2'></div>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-15'>
                    <p className='ft-sz-15 cl-white'>Total Size:</p>
                    <p className='ft-sz-15 cl-white'>{pageConst.contractNumLot} gm</p>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-10'>
                    <p className='ft-sz-15 cl-white'>Everyday Return:</p>
                    <p className='ft-sz-15 cl-green'>₹{Number(pageConst.investmentItemArr['i_commission'])*pageConst.contractNumLot}</p>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-15'>
                    <p className='ft-sz-15 cl-white'>Contract Duration:</p>
                    <p className='ft-sz-15 cl-white'>{pageConst.investmentItemArr['i_duration']} days</p>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-10'>
                    <p className='ft-sz-15 cl-white'>Total Tax:</p>
                    <p className='ft-sz-15 cl-red'>₹{pageConst.investmentItemArr['i_tax']}</p>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-10'>
                    <p className='ft-sz-15 cl-white'>Expected Profit:</p>
                    <p className='ft-sz-15 cl-green'>₹{(Number(pageConst.investmentItemArr['i_commission'])*pageConst.contractNumLot)*Number(pageConst.investmentItemArr['i_duration'])}</p>
                  </div>

                  <div className='w-100 row-view sb-view mg-t-20'>
                    <p className='ft-sz-17 cl-white'>Total Price + Tax:</p>
                    <p className='ft-sz-17 cl-white'>₹{(pageConst.contracTotalAmnt ? pageConst.contracTotalAmnt : Number(pageConst.investmentItemArr['i_price']))+Number(pageConst.investmentItemArr['i_tax'])}</p>
                  </div>

                  <div className='w-100 h-50-p ft-sz-20 pd-10 br-10 cl-white v-center mg-t-30 bg-blue-grad' onClick={()=>confirmBuy()}>
                    <span>Buy Now</span>
                  </div>

                  <div className='v-center mg-t-10'>
                    <img src={require("../icons/secure_icon_white.png")} className="h-w-15" alt="icon" />
                    <span className='cl-white ft-sz-14 mg-l-5'>100% Safe & Secure</span>
                  </div>

            </div>

        </div>

        
      </div>
    </div>
  );
}

export default InvestmentPurchase;