import React, { useState } from 'react';
import '../../MainStyle.css';
import BottomNavbar from '../other-components/BottomNavbar';
import { getStorage } from '../modals/Storage';

function Dp(){
    const [pageConst, setConstants] = useState({
        pageTitle: "Dp",
    });

    return (
      <div className="v-center bg-black">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-primary">

          <div className="col-view br-right-t br-left-t mg-b-15">

            <div className="col-view pd-10-15 bg-blue-grad">
              <div className="col-view mg-l-10">
                <p className="ft-sz-25 cl-white">Dp</p>
                <p className="ft-sz-14 cl-white">Showing Gold Bond Details.</p>
              </div>
            </div>

            <div className='col-view pd-5-15'>
              <div className={`w-100 v-center cl-l-white mg-t-30 ${getStorage("accountGoldBondId")!='' ? 'hide-v' : ''}`}>Nothing is here.</div>

              <div className={`w-100 col-view v-center mg-t-30 ${getStorage("accountGoldBondId")=='' ? 'hide-v' : ''}`}>
                <p className='ft-sz-18 cl-l-white'>DP Id:</p>
                <p className='ft-sz-25 cl-white ltr-s-2p2'>{getStorage("accountGoldBondId")}</p>

                <div className='txt-a-center ft-sz-14 cl-l-white pd-5-15 mg-t-15 br-5 bg-purple'>
                    This is an uniq Security Id for those users who Invested In Gold.
                </div>
              </div>
            </div>

          </div>

          <BottomNavbar activeBar="dp"/>
        </div>
      </div>
    );
}

export default Dp;