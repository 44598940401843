import React, { useState } from 'react';

const UploadShowImage = ({data,onImageUpdate}) => {

  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div className='w-100 pd-10 br-5 bg-purple'>
      <span className='cl-l-white'>{data}</span>

      {selectedImage && (
        <div>
          <img
            alt="not found"
            className="h-120-p"
            src={URL.createObjectURL(selectedImage)}
          />
        </div>
      )}

      <br />
      <br />
      
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          setSelectedImage(event.target.files[0]);
          onImageUpdate(data,event.target.files[0]);
        }}
      />
    </div>
  );
};

export default UploadShowImage;