import React,{useState} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import Home from './components/screens/Home';
import Invite from './components/screens/Invite';
import Recharge from './components/screens/Recharge';
import My from './components/screens/My';
import Login from './components/screens/Login';
import Register from './components/screens/Register';
import ForgotPassword from './components/screens/ForgotPassword';
import ChangePassword from './components/screens/ChangePassword';
import GameRecords from './components/screens/GameRecords';
import Transactions from './components/screens/Transactions';
import HelpSupport from './components/screens/HelpSupport';
import AllBankCards from './components/screens/AllBankCards';
import AddBankCard from './components/screens/AddBankCard';
import AddCryptoAddress from './components/screens/AddCryptoAddress';
import Withdraw from './components/screens/Withdraw';
import WithdrawRecords from './components/screens/WithdrawRecords';
import RechargeRecords from './components/screens/RechargeRecords';
import InviteLink from './components/screens/InviteLink';
import Rewards from './components/screens/Rewards';
import AgentReports from './components/screens/AgentReports';
import Activity from './components/screens/Activity';

import UnderMaintenance from './components/screens/UnderMaintenance';

import DeviceManager from './components/screens/DeviceManager';
import UpdateAccount from './components/screens/UpdateAccount';
import ChangeAvatar from './components/screens/ChangeAvatar';
import ChangeName from './components/screens/ChangeName';
import AllMembers from './components/screens/AllMembers';
import Winners from './components/screens/Winners';
import RedeemGiftcard from './components/screens/RedeemGiftCard';
import DailyCheckIn from './components/screens/DailyCheckIn';
import MyInvestments from './components/screens/MyInvestments';
import InvestmentDetails from './components/screens/InvestmentDetails';
import PurchaseSuccessful from './components/screens/PurchaseSuccessful';
import AccountProgress from './components/screens/AccountProgress';
import InvestmentPurchase from './components/screens/InvestmentPurchase';
import Dp from './components/screens/Dp';
import InvestmentSell from './components/screens/InvestmentSell';
import SellSuccessful from './components/screens/SellSuccessful';

function App() {

  return <>
  <Router>

    <Routes>
      <Route path='/home' element={<Home/>} />
      <Route path='/invite' element={<Invite/>} />
      <Route path='/winners' element={<Winners/>} />
      <Route path='/recharge' element={<Recharge/>} />
      <Route path='/my' element={<My/>} />
      <Route path='/gamerecords' element={<GameRecords/>} />
      <Route path='/transactions' element={<Transactions/>} />
      <Route path='/LG' element={<Login/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/RG' element={<Register/>} />
      <Route path='/register' element={<Register/>} />
      <Route path='/changepassword' element={<ChangePassword/>} />
      <Route path='/forgotpassword' element={<ForgotPassword/>} />
      <Route path='/withdraw' element={<Withdraw/>} />
      <Route path='/bankcards' element={<AllBankCards/>} />
      <Route path='/addbankcard' element={<AddBankCard/>} />
      <Route path='/addcryptoaddress' element={<AddCryptoAddress/>} />
      <Route path='/withdrawrecords' element={<WithdrawRecords/>} />
      <Route path='/rechargerecords' element={<RechargeRecords/>} />
      <Route path='/invitelink' element={<InviteLink/>} />
      <Route path='/rewards' element={<Rewards/>} />
      <Route path='/agentreports' element={<AgentReports/>} />
      <Route path='/devicemanager' element={<DeviceManager/>} />
      <Route path='/updateaccount' element={<UpdateAccount/>} />
      <Route path='/changeavatar' element={<ChangeAvatar/>} />
      <Route path='/changename' element={<ChangeName/>} />
      <Route path='/allmembers' element={<AllMembers/>} />
      <Route path='/activity' element={<Activity/>} />
      <Route path='/dailycheckin' element={<DailyCheckIn/>} />
      <Route path='/redeemgiftcard' element={<RedeemGiftcard/>} />
      <Route path='/myinvestments' element={<MyInvestments/>} />
      <Route path='/investmentdetails' element={<InvestmentDetails/>} />
      <Route path='/investmentpurchase' element={<InvestmentPurchase/>} />
      <Route path='/purchasesuccessful' element={<PurchaseSuccessful/>} />
      <Route path='/investmentsell' element={<InvestmentSell/>} />
      <Route path='/sellsuccessful' element={<SellSuccessful/>} />
      <Route path='/accountprogress' element={<AccountProgress/>} />
      <Route path='/helpsupport' element={<HelpSupport/>} />
      <Route path='/dp' element={<Dp/>} />

      <Route path='/um' element={<UnderMaintenance/>} />

      {/* default page */}
      <Route path='/' element={<Login/>} />
    </Routes>

  </Router>
  </>;
}

export default App;
